import { type PaymentStatus } from '@/controllers/graphql/generated';
import { type UserCourseMonthPaymentFragment } from '@/components/platform/Payment/graphql/generated/userCourseMonthPayment.fragment.generated';
import {
  type UserCourseMonthPaymentsQueryResult,
  useUserCourseMonthPaymentsQuery,
} from '@/components/platform/Payment/graphql/generated/userCourseMonthPayments.query.generated';
import { EMPTY_ARRAY } from '@/constants';

export type CourseMonthPaymentsHookOutput = [
  UserCourseMonthPaymentFragment[],
  UserCourseMonthPaymentsQueryResult
];

interface Options {
  statuses?: PaymentStatus[];
}

export const useUserCourseMonthPayments = (
  options?: Options,
): CourseMonthPaymentsHookOutput => {
  const inputData = options
    ? {
      variables: {
        options: {
          statuses: options?.statuses,
        },
      },
    }
    : undefined;

  const queryResult = useUserCourseMonthPaymentsQuery(inputData);

  const content = queryResult.data?.userCourseMonthPayments ?? EMPTY_ARRAY;

  return [
    content,
    queryResult,
  ];
};
